import revive_payload_client_4sVQNw7RlN from "/builds/qmex/q-telecom-public-api/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/qmex/q-telecom-public-api/frontend/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/builds/qmex/q-telecom-public-api/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/qmex/q-telecom-public-api/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/builds/qmex/q-telecom-public-api/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_TNUJsc4G2r from "/builds/qmex/q-telecom-public-api/frontend/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/composition.mjs";
import i18n_wvJtu1yLPy from "/builds/qmex/q-telecom-public-api/frontend/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/qmex/q-telecom-public-api/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/builds/qmex/q-telecom-public-api/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import fontawesome_cn2c4tOOHz from "/builds/qmex/q-telecom-public-api/frontend/plugins/fontawesome.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  composition_TNUJsc4G2r,
  i18n_wvJtu1yLPy,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  fontawesome_cn2c4tOOHz
]