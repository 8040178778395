import { default as indexFOLgWu3k7NMeta } from "/builds/qmex/q-telecom-public-api/frontend/pages/index.vue?macro=true";
import { default as languagekBh1UuamvOMeta } from "/builds/qmex/q-telecom-public-api/frontend/pages/language.vue?macro=true";
export default [
  {
    name: indexFOLgWu3k7NMeta?.name ?? "index",
    path: indexFOLgWu3k7NMeta?.path ?? "/",
    meta: indexFOLgWu3k7NMeta || {},
    alias: indexFOLgWu3k7NMeta?.alias || [],
    redirect: indexFOLgWu3k7NMeta?.redirect || undefined,
    component: () => import("/builds/qmex/q-telecom-public-api/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: languagekBh1UuamvOMeta?.name ?? "language",
    path: languagekBh1UuamvOMeta?.path ?? "/language",
    meta: languagekBh1UuamvOMeta || {},
    alias: languagekBh1UuamvOMeta?.alias || [],
    redirect: languagekBh1UuamvOMeta?.redirect || undefined,
    component: () => import("/builds/qmex/q-telecom-public-api/frontend/pages/language.vue").then(m => m.default || m)
  }
]